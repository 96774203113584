import React, { useState } from "react";
import moment from "moment";
import Modal from "../modal/modal";
import ScheduleAppointment from "../appointmentProfile/scheduleAppointment/scheduleAppointment";
import { appointmentApi } from "../../api";
import { toast } from "react-toastify";
import RescheduleAppointment from "../appointmentProfile/scheduleAppointment/rescheduleAppointment";

const ClaimsTable = ({
  data,
  defaultProfilePicture,

  setRefetch,
}) => {
  const [openRescheduleModal, setOpenRescheduleModal] = useState(false);
  const [selectedAppointment, setSelectedAppointment] = useState(null);

  return (
    <>
      <table>
        <thead>
          <tr className="table-head">
            <th style={{ fontFamily: "Avenir" }}>PATIENT </th>
            <th style={{ fontFamily: "Avenir" }}>PROVIDER</th>
            <th style={{ fontFamily: "Avenir" }}>SESSION ID</th>

            <th style={{ fontFamily: "Avenir" }}>ITEM NO</th>
            <th style={{ fontFamily: "Avenir" }}>CLAIM ID</th>
            <th style={{ fontFamily: "Avenir" }}>TYPE</th>
            <th style={{ fontFamily: "Avenir" }}>AMOUNT</th>
            <th style={{ fontFamily: "Avenir" }}>STATUS</th>
            <th style={{ fontFamily: "Avenir" }}>DATE</th>

            <th style={{ fontFamily: "Avenir" }}>FORM</th>

            <th></th>
          </tr>
        </thead>
        <tbody>
          {Array.isArray(data) &&
            data.map((row, index) => (
              <tr className="table-row" key={index}>
                <td>{row.patient?.name}</td>
                <td>{row.practitioner?.name}</td>
                <td>{row.transaction.sessionId}</td>

                <td> {row.transaction.itemNumber}</td>
                <td>{row.transaction.claimId}</td>
                <td>{row.transaction.type}</td>
                <td>${row?.charge?.amount}</td>

                <td>{row.transaction.status}</td>

                <td>
                  {moment(row.transaction.createdAt).format("DD/MM/YYYY")}
                </td>

                <td>
                  {row.transaction.claimPdfUrl ? (
                    <button
                      onClick={() => {
                        window.open(row.transaction.claimPdfUrl, "_blank");
                      }}
                      className="table-details-button"
                    >
                      Download
                    </button>
                  ) : (
                    "N/A"
                  )}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </>
  );
};

export default ClaimsTable;
