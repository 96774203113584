import { Route, Routes, HashRouter} from "react-router-dom";
import { CurrentPatients, InvitePatient, PendingPatients } from "./pages";
import DashboardLayout from "./layouts/DashboardLayouts";
import LoginPage from "./pages/LoginPage";
import PatientList from "./pages/Dashboard";
import ProviderList from "./pages/Provider"
import AppointmentList from "./pages/appointments"
import Billings from "./pages/billing";
import { ConfigProvider, Button } from 'antd';
import DashboardUserDetails from "./pages/DashboardUserDetails/DashboardUserDetails"
import BillingCustomTable from "./pages/billing";

function App() {
  return (
    <>
      <ConfigProvider
        theme={{
          token: {
            fontFamily: "Avenir, Arial, sans-serif", // Apply your custom font
          },
        }}
      >
        <HashRouter>
          <Routes>
            <Route path="/" element={<LoginPage />} />
            <Route path="/dashboard" element={<DashboardLayout />}>
              <Route index element={<PatientList />} />
              <Route path="/dashboard/billing" element={<Billings />} />
              <Route
                path="/dashboard/billing"
                element={<BillingCustomTable />}
              />
              <Route
                path="/dashboard/user-details/:id"
                element={<DashboardUserDetails />}
              />
            </Route>
            <Route path="/provider" element={<DashboardLayout />}>
              <Route index element={<ProviderList />} />
            </Route>
            <Route path="/appointments" element={<DashboardLayout />}>
              <Route index element={<AppointmentList />} />
            </Route>

            <Route path="/invite" element={<InvitePatient />} />
            <Route path="/current-patients" element={<CurrentPatients />} />
            <Route path="/pending-patients" element={<PendingPatients />} />
          </Routes>
        </HashRouter>
      </ConfigProvider>
    </>
  );
}

export default App;

