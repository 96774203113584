import React, { useState } from "react";
import moment from "moment";
import "./tables.scss";

const BillingTable = ({
  data,
  defaultProfilePicture,

  setRefetch,
}) => {

  return (
    <>
      <table width={"100%"}>
        <thead>
          <tr className="table-head">
            <th style={{ fontFamily: "Avenir" }}>Patient</th>
            <th style={{ fontFamily: "Avenir" }}>Provider</th>
            <th style={{ fontFamily: "Avenir" }}>Session ID</th>
            <th style={{ fontFamily: "Avenir" }}>Provider Fee</th>
            <th style={{ fontFamily: "Avenir" }}>Total Fee</th>
            <th style={{ fontFamily: "Avenir" }}>Status</th>
            <th style={{ fontFamily: "Avenir" }}>Date</th>
            <th style={{ fontFamily: "Avenir" }}>Provider Invoice</th>
            <th style={{ fontFamily: "Avenir" }}>Fee Invoice</th>

            <th></th>
          </tr>
        </thead>
        <tbody>
          {Array.isArray(data) &&
            data.map((row, index) => (
              <tr className={"table-row"} key={index}>
                <td>{row.patient.name}</td>
                <td>{row.providerName}</td>
                <td>{row.sessionId}</td>
                <td>${row.providerFee}</td>
                <td> ${row.totalAmount}</td>
                <td>{row.status}</td>
                <td>{moment(row.date).format("DD/MM/YYYY")}</td>
                <td>
                  {row.transaction.providerTaxURL ? (
                    <button
                      onClick={() => {
                        window.open(row.transaction.providerTaxURL, "_blank");
                      }}
                      className="table-details-button"
                    >
                      Download
                    </button>
                  ) : (
                    "N/A"
                  )}
                </td>
                <td>
                  {row.transaction.feeTaxURL ? (
                    <button
                      onClick={() => {
                        window.open(row.transaction.feeTaxURL, "_blank");
                      }}
                      className="table-details-button"
                    >
                      Download
                    </button>
                  ) : (
                    "N/A"
                  )}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </>
  );
};

export default BillingTable;
