import React, { useEffect, useState } from "react";
import "../../components/appointmentCustomTable.scss";
import { useNavigate } from "react-router-dom";

import { Skeleton } from "antd";

import { api, appointmentApi, fetcher } from "../../api";
import Button from "../../components/Button";
import searchIcon from "../../assets/icons/search.svg";

import { useSelector } from "react-redux";
import CurrentAppointmentTable from "../../components/Tables/currentAppointments";
import moment from "moment";
import { toast } from "react-toastify";
import styles from "./billing.module.scss";
import BillingTable from "../../components/Tables/billing";
import ClaimsTable from "../../components/Tables/claims";

const BillingCustomTable = ({ refetch, setRefetch }) => {
  const [displayedItems, setDisplayedItems] = useState([]);
  const initialStartDate = new Date().toISOString().split("T")[0];
  const initialFinishDate = new Date().toISOString().split("T")[0];
  const [startDate, setStartDate] = useState(initialStartDate);
  const [finishDate, setFinishDate] = useState(initialFinishDate);
  const [selectedProvider, setSelectedProvider] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const [searchInput, setSearchInput] = useState("");
  const { user } = useSelector((state) => state.auth);

  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("Billings");
  const navigate = useNavigate();
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const practiceId =
    user?.practice && user.practice.length > 0 ? user.practice[0].id : null;

  const fetchData = async (activeTab, currentPage, limit) => {
    try {
      setLoading(true);
      const paymentType = activeTab === "Billings" ? "Billing" : "Claims";

      const response = await api.get(`/practices/transactions`, {
        params: {
          paymentType,
          page: currentPage,
          limit: 10,
          practiceId,
          //   practitionerId,
        },
      });

      if (
        response?.data &&
        response?.data?.data &&
        response.data?.data?.history
      ) {
        setDisplayedItems(response?.data?.data?.history);
        setTotalPages(response?.data?.data?.totalPages);
      } else {
        console.error("Invalid response structure:", response.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDownloadCsv = async () => {
    setLoading(true);
    try {
      const formattedStartDate = moment(startDate).format("YYYY-MM-DD");
      const formattedFinishDate = moment(finishDate).format("YYYY-MM-DD");
      const response = await api.get(`/practices/transactions/download`, {
        params: {
          page: currentPage,
          limit: 20,
          startDate: formattedStartDate,
          endDate: formattedFinishDate,
          practiceId,
          //   practitionerId: selectedProvider,
        },
        responseType: "blob",
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "transactions.csv");
      document.body.appendChild(link);
      link.click();
      link.remove();
      setLoading(false);
      toast.success("Downloaded successfully");
    } catch (error) {
      console.log("error", error);
      toast.error("Error downloading transactions", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(activeTab, currentPage, 10);
  }, [currentPage, activeTab, practiceId, refetch]);

  const displayPageNumbers = () => {
    let pageNumbers = [];
    const maxPageNumbers = 8;

    for (
      let i = currentPage - 1;
      i > 0 && pageNumbers.length < maxPageNumbers / 2;
      i--
    ) {
      pageNumbers.unshift(i);
    }

    pageNumbers.push(currentPage);

    for (
      let i = currentPage + 1;
      i <= totalPages && pageNumbers.length < maxPageNumbers;
      i++
    ) {
      pageNumbers.push(i);
    }

    while (pageNumbers.length < maxPageNumbers && pageNumbers[0] > 1) {
      pageNumbers.unshift(pageNumbers[0] - 1);
    }

    while (
      pageNumbers.length < maxPageNumbers &&
      pageNumbers[pageNumbers.length - 1] < totalPages
    ) {
      pageNumbers.push(pageNumbers[pageNumbers.length - 1] + 1);
    }

    return pageNumbers;
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  //search
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      try {
        let params = {
          page: currentPage,
          limit: 10,
          paymentType: activeTab === "Billings" ? "Billing" : "Claims",
        };
        if (searchInput) {
          params.query = searchInput;
        }
        const response = await api.get("/practices/transactions", {
          params: {
            ...params,
          },
        });

        if (
          response?.data &&
          response?.data?.data &&
          response.data?.data?.history
        ) {
          setDisplayedItems(response?.data?.data?.history);
          setTotalPages(response?.data?.data?.totalPages);
        } else {
          console.error("Invalid response structure:", response.data);
        }

        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    const debounceSearch = setTimeout(() => {
      fetchData();
    }, 1000);

    return () => clearTimeout(debounceSearch);
  }, [currentPage, searchInput]);

  const displayPageNumber = (
    <div className="paginationContainer">
      <span
        className={`page-number ${currentPage === 1 ? "disabled" : ""}`}
        onClick={handlePrevPage}
      >
        &lt; Prev
      </span>

      {displayPageNumbers().map((pageNumber) => (
        <span
          key={pageNumber}
          className={`page-number ${
            pageNumber === currentPage ? "active" : ""
          }`}
          onClick={() => handlePageChange(pageNumber)}
        >
          {pageNumber}
        </span>
      ))}

      <span
        className={`page-number ${
          currentPage === totalPages ? "disabled" : ""
        }`}
        onClick={handleNextPage}
      >
        Next &gt;
      </span>
    </div>
  );

  return (
    <div className={styles.root}>
      <h1 className={styles.header}>Invoice & Claims</h1>
      <div className={styles.options}>
        <div className={styles.filterContainer}>
          <div className={styles.filterToggle}>
            <div className={styles.searchContainer}>
              <img src={searchIcon} alt="" />
              <input
                type="search"
                placeholder="Search using patient name"
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
              />
            </div>

            {/* <div>
              <Button
                label="Download Transactions"
                onClick={handleDownloadCsv}
                isLoading={loading}
              />
            </div> */}
          </div>
        </div>
      </div>
      <div className="custom-table">
        <div className="custom-table-top-tabs">
          <div className="custom-table-top-tabs-items">
            <span
              onClick={() => setActiveTab("Billings")}
              className={activeTab === "Billings" ? "tabs-active" : ""}
            >
              Invoice
            </span>
            <span
              onClick={() => setActiveTab("Claims")}
              className={activeTab === "Claims" ? "tabs-active" : ""}
            >
              Claims
            </span>
          </div>
        </div>
        <div>
          {loading ? (
            Array.from({ length: 5 }, (_, index) => (
              <div className="skeleton" key={index}>
                <Skeleton key={index} paragraph={false} active />
              </div>
            ))
          ) : (
            <>
              {activeTab === "Billings" ? (
                <BillingTable
                  setRefetch={setRefetch}
                  navigate={navigate}
                  data={displayedItems || []}
                />
              ) : (
                <ClaimsTable
                  setRefetch={setRefetch}
                  navigate={navigate}
                  data={displayedItems || []}
                />
              )}

              <div className="paginationContainer">{displayPageNumber}</div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default BillingCustomTable;
